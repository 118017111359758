<template>
  <div class='date-range-filters flex flex-row justify-start items-center text-sm'>
    <el-date-picker
      style='width: 15rem;'
      v-model='dateRange'
      type='daterange'
      range-separator='~'
      start-placeholder='Start date'
      end-placeholder='End date'
      size='large'
      :clearable='false'
      :picker-options='pickerOptions'>
    </el-date-picker>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'DateRangeFilters',
  props:  {
    'defaultDateRange': {
      type: Array,
      required: false,
      default: function () {
        return [
          dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
          dayjs().format('YYYY-MM-DD')
        ]
      }
    },
  },
  data () {
    return {
      dateRange: [
        this.defaultDateRange[0], 
        this.defaultDateRange[1]
      ],
      pickerOptions: {
        shortcuts: [{
          text: 'Today',
          onClick (picker) {
            const end   = dayjs().format('YYYY-MM-DD')
            const start = dayjs().format('YYYY-MM-DD')
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last week',
          onClick (picker) {
            const end   = dayjs().format('YYYY-MM-DD')
            const start = dayjs().subtract(7, 'day')
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last month',
          onClick (picker) {
            const end = dayjs().format('YYYY-MM-DD')
            const start = dayjs().subtract(1, 'month')
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last 3 months',
          onClick (picker) {
            const end = dayjs().format('YYYY-MM-DD')
            const start = dayjs().subtract(3, 'month')
            picker.$emit('pick', [start, end])
          }
        }]
      },
    }
  },
  watch: {
    dateRange: {
      handler: function () {
        this.$emit('update-date-range', { startDate: this.startDate, endDate: this.endDate })
      },
      immediate: true,
    },
  },
  computed: {
    startDate () {
      return this.dateRange ? dayjs(this.dateRange[0]).format('YYYY-MM-DD') : null
    },
    endDate () {
      return this.dateRange ? dayjs(this.dateRange[1]).format('YYYY-MM-DD') : null
    },
  },
}
</script>
